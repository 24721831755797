import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validate } from 'validate.js';
import _ from 'lodash';
import images from '../../../assets/img'
import TextInput from "../../../components/Common/TextInput";
import { MessageType } from '../../../util/enum';
import validationSchema from './schema';
import MessageBox from '../../../components/Common/MessageBox';

function ResetPassword() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState('')
    const [values, setvalues] = useState({ newPassword: '', confirmPassword: '' });
    const [type, setType] = useState();
    const [message, setMessage] = useState();

    const handleChange = (field, value) => {
        setvalues({ ...values, [field]: value.trim() });
    }
    const data = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword
    }
    const validateForm = () => {
        const error = validate(data, validationSchema);
        setErrors(error);
        if (error) {
            const message = !_.isEmpty(values.newPassword) && !_.isEmpty(values.confirmPassword) ?
                errors[Object.keys[error][0][0]] : "Please enter a value in the field"
            setType(MessageType.Error);
            setMessage(message);
            return false;
        } else {
            return true;
        }
    }
    const resetPasswordhandler = async (e) => {
        if (validateForm()) { 
            navigate('/resetpassword/confirmation')
        } 
    }


    return (
        <>
            <MessageBox message={message} setMessage={setMessage} type={type} />
            <section className="login-section">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="main-img-div">
                                <h3 className="log-txt">Streamline your process<br />with ease</h3>
                                <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="login-content">
                                <div className="logomain">
                                    <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
                                        <p className="tm">powered by SecondOpinionExpert</p></h2>
                                    <p>Facility workflow management</p>
                                    <div className="reset-psd">
                                        <h2>Reset your password</h2>
                                    </div>
                                </div>
                                <TextInput
                                    maxLength={100}
                                    type="password"
                                    value={values.newPassword}
                                    error={errors && errors.newPassword}
                                    name="newPassword"
                                    id="password"
                                    placeholder="New Password"
                                    label="New Password"
                                    className="input form-control"
                                    onChange={(e) => handleChange("newPassword", e.target.value)}
                                    wrapperClassName='user-name'
                                />

                                {/* <input type="password" className="input form-control" id="password" placeholder=" " required name="password" />
                                    <label htmlFor="password" className="label ">New password</label>  */}
                                {/* <div className="inputContainer user-name"> */}
                                <TextInput
                                    maxLength={100}
                                    type="password"
                                    value={values.confirmPassword}
                                    error={errors && errors.confirmPassword}
                                    name="confirmPassword"
                                    id="confirm-password"
                                    placeholder="Confirm Password"
                                    label="Confirm Password"
                                    className="input form-control"
                                    onChange={(e) => handleChange("confirmPassword", e.target.value)}
                                    wrapperClassName='user-name'
                                />
                                {/* <input type="password" className="input form-control" id="confirm-password" placeholder=" " required name="confirm-password" />
                                    <label htmlFor="confirm-password" className="label ">Confirm password</label> */}
                                {/* </div> */}
                                <div className="d-grid col-12 mx-auto mt-20 mb-20">
                                    <div className="btnbox">
                                        <button className="fullwidthbtn ft-size" onClick={resetPasswordhandler}>Reset password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default ResetPassword;