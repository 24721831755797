
const validationSchema = {
    email: {
        presence: { allowEmpty: false, message: '^Email is required' },
        email: { required: true, message: '^Please enter a valid email.' },
        length: {
          maximum: 64
        }
      },
      password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
          maximum: 25
        }
      }
}

export default validationSchema;