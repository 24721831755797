let passwordErrorMessage = (

    <div>
  
      Password must include:
  
      <p>
  
        * at least 8 characters long
  
        <br /> * at least one lowercase letter (a-z)
  
        <br /> * at least one uppercase letter (A-Z)
  
        <br /> * at least one number (0-9)
  
        <br /> * at least one special character (!@#$)
  
        <br />
  
      </p>
  
    </div>
  
  );
const validationSchema = {
    newPassword: {
        presence: {
            allowEmpty: false,
            message: "cannot be empty"
        },
        length: {
            minimum: 8,
            message: "must be at least 8 characters"
        },
        format: {
            pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/,
            message: passwordErrorMessage
        }
    },
    confirmPassword: {
        presence: {
            allowEmpty: false,
            message: "cannot be empty"
        },
        equality: {
            attribute: "newPassword",
            message: "must match password field"
        }
    }
};

export default validationSchema
