import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import AddProject from "../AddProject";

const Projects = ({ list }) => { 

    const getEllipsis =(name,length) =>{
        if(typeof name === 'string'){
        return name.length >length ? name.substring(0, length) + "..." : name;
        }
        return name;
 
  }
    return (<>
     <AddProject />
     <div className="col-md-6">
        <div className="prj-data main-dashboard ">
            <div className="lin-dt">
                <h2>Projects </h2>
                <div className="dropdown d-inline-block cst-dropdown"> 
                  <Link to={"/project"} className="p_viewall"> <span >View all</span> </Link>
                </div> 
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="crt-project">
                        <div className="dsh-border">
                            <Link  data-bs-toggle="modal" data-bs-target="#createproject" >
                                <Icon icon={"material-symbols:add"} className="iconify" data-bs-toggle="modal" data-bs-target="#createproject" />
                            </Link> 
                        </div>
                        <Link data-bs-toggle="modal" data-bs-target="#createproject" ><h2>Create Project</h2></Link> 
                    </div>
                </div>
                {list && list.map((p, i) => {
                    // let capitalizedTitle = p && p?.Title.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                    return <div className="col-md-6" key={i}>
                        <div className="prj-overview">
                            <div className="ovr-data">
                                <Link to={`/project/${p.ID}`}><Icon icon={"material-symbols:add-home-work-outline"} className="iconify" />
                                </Link>
                            </div>
                            <Link to={`/project/${p.ID}`}><h2 title={p && p?.Title}>{getEllipsis(p && p?.Title,15)}<span>0 new tasks</span></h2> </Link>
                        </div>               
                     </div> 
                })
                }
            </div>
        </div>
    </div>
    </>
    )
}

export default Projects;