export const emailSchema = {
    Email: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
        email: {
            required: true, message: "^Enter a valid email"
        }
    },
};
export const OTPSchema = {
    OTP: {
        presence: { allowEmpty: false, message: "^Please fill the required field" },
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
        },
        length: {
            is: 4,
        },
    },
};

