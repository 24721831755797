import client from "../../services/api-client";
import { errorHandler } from "../error/action";
// import { UserStatus } from "../../util/enum";
import { pick } from "lodash";

const authManagement = client.service("authManagement");

export const login = (credentials, isRemember) => async (dispatch) => { 
  try {
    const res = await client.authenticate({
      strategy: "local",
      ...pick(credentials, ["Email", "PasswordHash"]),
    }); 
    if (isRemember) {
      localStorage.setItem("rememberMe", JSON.stringify({ ...credentials, isRemember }));
    }
    else { localStorage.removeItem("rememberMe"); }

    if (res && res.AspNetUsers) {
      dispatch({
        type: "LOGIN",
        data: res.AspNetUsers,
      });
      return {
        res: true,
        isVerified: true,
        profile: res.AspNetUsers,
      };

    }
  } catch (error) {
    errorHandler(error); 
  }
};



export const otpAuthenticate = (credentials) => async (dispatch) => {
  //use the feathers client to authenticate
  try {
    const res = await client.service("guestauthentication").create({
      strategy: "otp",
      ...credentials,
    })
    if (res && res.WMS_ProjectFacilityUsers) {
      localStorage.setItem("feathers-jwt", JSON.stringify(res.accessToken));
      dispatch({
        type: "LOGIN",
        data: res.WMS_ProjectFacilityUsers,
      });
    }
    return res;
  } catch (error) {
    let err = error;
    errorHandler(err);
    return false;
  }
};

export const isAlive = async (dispatch) => {
  // Try to authenticate using an existing token
  try {
    const token = localStorage.getItem("feathers-jwt");
    if (token) { 
      const res = await client.reAuthenticate();
      if (res.accessToken) {
        return res.AspNetUsers;
      }
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("feathers-jwt");
    localStorage.removeItem("persist:root");
    await client.logout();
    dispatch({
      type: "LOGOUT",
      data: {},
    });
    return true;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

// export const verifyUser = (token) => async (dispatch) => {
//   try {
//     if (token) {
//       //Find the user with the given token
//       let user = await client.service("users").find({
//         query: {
//           verifyToken: token,
//         },
//       });
//       return user;
//     }
//     return false;
//   } catch (e) {
//     return false;
//   }
// };

export const resetPassword = (password, uid) => async (dispatch) => {
  try {
    const res = await client
      .service("users")
      .patch(uid, { password, resetpassword: true });
    if (res) {
      dispatch({
        type: "UPDATE_RESET_PASSWORD",
        data: res,
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const verifyUser = (token) => async (dispatch) => {
  try {
    if (token) {
      const res = await client.authManagement.verifySignupLong(token);
      if (res) {
        return true
      }
      return false;
    }
  } catch (e) {
    // let error = { message: "Email address is already verified." };
    // errorHandler(e);
    return false;
  }
};

export const resetPasswordWithToken =
  (token, password, resetpassword) => async (dispatch) => {
    try {
      const res = await authManagement.create({
        action: "verifySignupSetPasswordLong",
        value: {
          token, // compares to .verifyToken
          password, // new password 
        },
      });

      if (res) {
        return true;
      }
      return false;
    } catch (error) {
      errorHandler(error, 'activate');
      return false;
    }
  };

export const forgotPasswordWithToken = (token, password) => async (dispatch) => {
  try {
    const res = await authManagement.create({
      action: "resetPwdLong",
      value: {
        token, // compares to .resetToken
        password, // new password
      },
    });

    if (res) {
      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  let notifierOptions = { email: email };
  try {
    const res = await authManagement.create({
      action: "sendResetPwd",
      value: email, // {email}, {token: verifyToken}
      notifierOptions, // options passed to options.notifier, e.g. {preferredComm: 'email'} 
    });

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const verifyUserToken = (token, isReset) => async (dispatch) => {
  try {
    if (token) {
      //Find the user with the given token
      let user = await client.service("tokenexpiration").find({
        query: {
          verifyToken: token,
          isReset: isReset
        },
      });
      return user;
    }
    return false;
  } catch (e) { 
    return false;
  }
};