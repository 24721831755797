function Enum() {
  this.self = arguments[0];
}
Enum.prototype = {
  keys: function () {
    return Object.keys(this.self);
  },
  values: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return me.self[key];
    });
  },
  getKeyValuePairs: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return { name: key, value: me.self[key] };
    });
  },
  getValueByName: function (key) {
    return this.self[
      this.keys(this.self)
        .filter(function (k) {
          return key.toString() === k;
        })
        .pop() || ''
    ];
  },
  getNameByValue: function (value) {
    var me = this;
    return (
      this.keys(this.self)
        .filter(function (k) {
          return me.self[k] === value;
        })
        .pop() || null
    );
  }
};



export const UserStatus = {
  Inactive: 0,
  Active: 1,
  Deleted: 2
};

export const RoleType = {
  Admin: 1,
};
export const RoleTypeDesc = new Enum({
  "Admin": 1,
})

export const UserStatusDesc = new Enum({
  "Inactive": 0,
  "Active": 1,
})

export const gender = {
  Select: 0,
  Male: 1,
  Female: 2,
  Other: 3
}

export const GenderDesc = new Enum({
  1: 'Male',
  2: 'Female',
  3: 'Other'
});


export const MessageType = {
  Success: "Success",
  Error: "Error"
};

export const ProjectStatus = {
  Open: 1,
  InProgress: 2,
  Abandoned: 3,
  OnHold: 4,
  Completed: 5
}
export const ProjectHealth = {
  AtRisk: 1,
  RequiresAttention: 2,
  Ontrack: 3,
}
export const FacilityUserForm = {
  DEMOGRAPHICS: 1,
  SERVICES: 2,
  OWNERSHIP: 3,
  INSURANCE: 4,
  PAYROLL: 5,
  DOCS: 6,
}
export const FacilityUserFormDoc = {
  DEMOGRAPHICS: 1,
  SERVICES: 2,
  OWNERSHIP: 3,
  INSURANCE: 4,
  PAYROLL: 5,
  DOCUMENTS: 6
}
export const CategoryType = {
  "Onboarding Documents": 1,
  "Facility Profile": 2,
  "Facility Binder": 3
}
export const TaskStatus = new Enum({
  New: 1,
  'In Progress': 2,
  'On Hold': 3,
  'Request Sent': 4,
  Scheduled: 5,
  Completed: 6,
  Canceled: 7
})
export const TaskDateFilter = new Enum({
  'This week': 1,
  'Last week': 2,
  'This month': 3,
  'Last month': 4
})
export const Priority = {
  Low: 1,
  Medium: 2,
  High: 3
}