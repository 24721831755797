import React, { useState } from 'react';
import TextInput from "../../../components/Common/TextInput";
import images from '../../../assets/img';
import { Link,  useNavigate } from 'react-router-dom';
import { otpAuthenticate } from '../../../store/auth/action'; 
import { validate } from 'validate.js';
import { emailSchema, OTPSchema } from './schema';
import OtpInput from 'react-otp-input';
import image from "../../../assets/img"
import { MessageType } from "../../../util/enum";
import MessageBox from "../../../components/Common/MessageBox";
import { connect } from 'react-redux';

const Login = ({ otpAuthenticate }) => {
    const navigate = useNavigate(); 
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState();
    const [message, setMessage] = useState();

    const handleChange = (field, value) => {
        setValues({ ...values, [field]: value });
    };
    const validateLogin = () => {
        const errors = values.OTP ? validate(values, OTPSchema) : validate(values, emailSchema);
        setErrors(errors || {});
        let valid = errors ? false : true;
        if (!valid) {
            setType(MessageType.Error);
            setMessage(errors[Object.keys(errors)[0]][0]);

        } else {
            setErrors({});
        }
        return valid;
    }

    const loginHandler = async (e) => {
        let isValid = validateLogin();
        if (isValid) {
            setIsLoading(true);
            let result = await otpAuthenticate(values);
            if (result?.code && result.code == 200) {
                setTimeout(() => {
                    setIsLoading(false);
                    setIsOtpScreen(true);
                }, 500);
            }
            else if (result?.accessToken) {
                setTimeout(() => {
                    navigate("/facility-profile");
                    setIsLoading(false);
                    setIsOtpScreen(false);
                    setValues({});
                }, 500);
            }
            else {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500)
            }
        }
    }

    const renderOTPSCreen = () => {
        return <div className="col-md-7 gl-right">
            <div className="frm-sec">
                <div className="login-content">
                    <h2>Login to continue</h2>
                    <div className="email-edit">
                        <span>Email</span>
                        <div className="em-dt">{values.Email} <a href="#" onClick={() => setIsOtpScreen(false)}><span className="iconify" data-icon="material-symbols:edit-outline"></span></a>
                        </div>
                    </div>
                    <div className="inputContainer user-name d-flex justify-content-center">
                        <OtpInput
                            inputStyle={{
                                margin: "0 8px",
                                height: "60px",
                                width: "60px",
                                fontSize: "20px",
                                textAllign: "center",
                                border: "none",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                boxShadow: '0px 4px 21px rgba(0, 0, 0, 0.16)'
                            }}
                            value={values?.OTP}
                            onChange={(e) => handleChange("OTP", e)}
                            numInputs={4}
                            separator={<span>-</span>}
                        />
                    </div>
                    <div className="d-grid col-12 mx-auto mt-20 mb-20">
                        <div className="btnbox"><a href="#" onClick={!isLoading && loginHandler} className="fullwidthbtn ft-size" type="button">
                        {isLoading && <img
                                src={image.loader.default}
                                className={'load__icon1'}
                                alt="loader"
                                width={20}
                                height={20}
                            />}
                        {!isLoading && `Login`}</a></div>
                    </div>
                    <div className="d-grid text-center ">
                        <span className="sp-line">Didn’t receive code ?  <a href="#" onClick={loginHandler}>Resend code</a></span>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderLoginScreen = () => {
        return  <div className="col-md-7 gl-right">
        <div className="frm-sec">
            <div className="login-content">
                <h2>Login to continue</h2>
                <div className="inputContainer user-name">
                    <TextInput
                        type="text"
                        maxLength={100}
                        wrapperClassName='user-name'
                        value={values?.Email}
                        error={errors && errors.Email}
                        name="Email"
                        id=""
                        placeholder="Email"
                        label="Email"
                        className="input form-control"
                        onChange={(e) => handleChange("Email", e.target.value)}
                        onKeyPress={loginHandler}
                    />
                </div>
                <div className="d-grid col-12 mx-auto mt-20 mb-20">
                    <div className="btnbox">
                        <button className="fullwidthbtn ft-size" type="button" onClick={!isLoading && loginHandler}>
                            {isLoading && <img
                                src={image.loader.default}
                                className={'load__icon1'}
                                alt="loader"
                                width={20}
                                height={20}
                            />}
                            {!isLoading && `Send 4 digit code` }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }

    return <div className="container-fluid">
        <MessageBox message={message} setMessage={setMessage} type={type} />
        <div className="row row-flex">
            <div className="col-md-5 gl-left">
                <h1>SecondOpinionExpert, Inc.</h1>
                <h4>Point-of-Care Services</h4>
                <div className="min-data">
                    <h3 className="log-txt">Providing Exceptional
                        Medical Care</h3>
                </div>
            </div>
            { isOtpScreen ? renderOTPSCreen() : renderLoginScreen() }
        </div>
    </div> 

   

    // return (
    //     <>
    //         <MessageBox message={message} setMessage={setMessage} type={type} />
    //         <div className="row">
    //             <div className="col-lg-7 col-md-6">
    //                 <div className="main-img-div">
    //                     <h3 className="log-txt">Streamline your process<br />with ease</h3>
    //                     <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
    //                 </div>
    //             </div>
    //             <div className="col-lg-4 col-md-6">
    //                 <div className="login-content">
    //                     <div className="logo">
    //                         <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
    //                             <p className="tm">powered by SecondOpinionExpert</p></h2>
    //                         <p>Facility workflow management</p>
    //                     </div>
    //                     {
    //                         isOtpScreen ? <div className="inputContainer mb-0 d-flex justify-content-center">
    //                             <OtpInput
    //                                 inputStyle={{
    //                                     margin: "0 8px",
    //                                     height: "60px",
    //                                     width: "60px",
    //                                     fontSize: "20px",
    //                                     textAllign: "center",
    //                                     border: "none",
    //                                     borderRadius: "5px",
    //                                     backgroundColor: "#fff",
    //                                     boxShadow:'0px 4px 21px rgba(0, 0, 0, 0.16)'
    //                                 }}
    //                                 value={values?.OTP}
    //                                 onChange={(e) => handleChange("OTP", e)}
    //                                 numInputs={4}
    //                                 separator={<span>-</span>} 
    //                             />
    //                         </div> : 
    //                                 <TextInput
    //                                     type="text"
    //                                     wrapperClassName='user-name'
    //                                     value={values?.Email}
    //                                     error={errors && errors.Email}
    //                                     name="email"
    //                                     id=""
    //                                     placeholder="Email"
    //                                     label="Email"
    //                                     className="input form-control"
    //                                     onChange={(e) => handleChange("Email", e.target.value)}
    //                                     onKeyPress={loginHandler}
    //                                 /> 
    //                     }
    //                     <div className="d-grid col-12 mx-auto mt-5 mb-20">
    //                         <div className="btnbox">
    //                             <button className="fullwidthbtn ft-size" type="button" onClick={loginHandler}>
    //                                 {isLoading && <img
    //                                     src={image.loader.default}
    //                                     className={'load__icon1'}
    //                                     alt="loader"
    //                                     width={20}
    //                                     height={20}
    //                                 />}
    //                                 {!isLoading && (isOtpScreen ? `Login` : `Get OTP`)}
    //                             </button>
    //                         </div>
    //                     </div>
    //                     <div className="d-grid text-center ">
    //                         <span className="sp-line">Issues logging in? <Link>Visit our Help Desk</Link></span>
    //                         <span className="sp-line-2">By using the CLHFLOW™ Platform you agree to <Link>our Terms of Service</Link> and <Link>Privacy Policy </Link></span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
}
const mapDispatchToProps = { otpAuthenticate };
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Login);