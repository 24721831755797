import React, { useState, useEffect, Fragment } from "react";
import MessageBox from "../Common/MessageBox";
import image from "../../assets/img";
import { Link, useNavigate } from "react-router-dom";
import { MessageType, ProjectStatus } from "../../util/enum";
import validate from "validate.js";
import { createProject, getProjectList, } from "../../store/projects/action";
import { connect } from "react-redux";
import _, { map, valuesIn } from "lodash";
import { ValidationSchema, PValidationSchema, SValidationSchema } from './schema'
import { Icon } from "@iconify/react";
import TextInput from "../Common/TextInput";
import Modal from "../Common/Modal";
import DatePickerComponent from "../DatePicker";
import moment from "moment";
import { getWorkFlows } from "../../store/projects/action";
import { splitContactName } from "../../util/helper";

const AddProject = ({ createProject, workflows, getWorkFlows, project, _modalID = "createproject", updateOverView, getProjectList }) => {
    const navigate = useNavigate();
    const initialState = {
        values: {
            Title: "",
            Description: "",
            WorkflowId: null,
            EndDate: "",
            WMS_ProjectFacilityUserAccess: []
        },
        errors: {
            Title: "",
            Description: "", 
            EndDate: "",
            WorkflowId: null,
            primaryContact:[{ContactName: '', Email: '',}],
            secondaryContacts:[{ContactName: '', Email: '',}]
        },
    };

    const [values, setValues] = useState(initialState.values);
    const [errors, setErrors] = useState(initialState.errors);
    const [type, setType] = useState();
    const [workFlowsList, setWorkFlowsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState();
    const [primaryContact, setPrimaryContact] = useState([{ContactName: '', Email: '', Title: 1}])
    const [secondaryContacts, setSecondaryContacts] = useState([])
    const handlecontactsAdd = () => {
        setSecondaryContacts([...secondaryContacts, { ContactName: '', Email: '', Title: 2  }]);
    };

    const handleContactsDelete = (index) => {
        const updatedFields = [...secondaryContacts];
        updatedFields.splice(index, 1);
        setSecondaryContacts(updatedFields)
    }
   
    const handleAccessibleUsers = (field, value, index, isPrimaryContact = false) => {
        if (isPrimaryContact) { 
            let primaryContacts = [...primaryContact]; 
            primaryContacts[0][field] = value; 
            setPrimaryContact(primaryContacts)
        } else {
            let secondaryContact = [...secondaryContacts] ; 
            secondaryContact[index][field] = value;
            setSecondaryContacts(secondaryContact)
        } 
    }

    useEffect(() => {
        if (workflows) {
            let res = _.map(workflows, (p) => {
                return { value: Number(p.ID), label: p.Title };
            });
            setWorkFlowsList(res);
        }
    }, [workflows]);

    useEffect(() => {
        getWorkFlows();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(project)) { 
            setValues(project);
            setPrimaryContact(project?.PrimaryContact)
            setSecondaryContacts(project?.OtherContacts)
        }

    }, [project])

    const handleChange = (field, value) => {
        setValues((prev) => ({ ...prev, [field]: value }));
    }
    const validateForm = () => { 
        let errors = validate(values, ValidationSchema);
        let perrors = validate({primaryContact}, PValidationSchema);
        let serrors = validate({secondaryContacts}, SValidationSchema);
        let message = errors && setMessage(errors[Object.keys(errors)[0]][0][0]);
        if(perrors)
        {
            if(!errors)errors={}
            errors.primaryContact = perrors.primaryContact;
            message = !message ? Object.values(Object.values(perrors.primaryContact[0])[0])[0][0] : message
        } 
        if(serrors)
        {
            if(!errors)errors={}
            errors.secondaryContacts = serrors.secondaryContacts;
            message = !message ? Object.values(Object.values(serrors.secondaryContacts[0])[0])[0][0] : message
        } 
        setErrors(errors || {});
        let valid = errors ? false : true;
        if (!valid) {
             setType(MessageType.Error);
            setMessage(message);
        } else {
            setErrors({});
        }
        return valid
    
    }

    const OK = async () => {
        window.$(`#commonModal_success`).modal('hide');
        window.$(`#commonModal_activation`).modal('hide');
        setIsLoading(false);
        setValues(initialState.values);
        setErrors(initialState.errors);
        // navigate("/dashboard");
    }
    const resetForm = () => {
        if (values?.ID > 0) {
            setErrors(initialState.errors)
            setValues(project)

        } else {
            setValues(initialState.values);
            setErrors(initialState.errors);
            setPrimaryContact([{FName: '', Email: '', Title: 1}]);
            setSecondaryContacts([]);
        }
    }

    const onSendInvite = async () => {
        let finalSubmit = validateForm();
        if (finalSubmit) {
            setIsLoading(true); values.WMS_ProjectFacilityUserAccess = [...primaryContact, ...secondaryContacts]?.map((item)=>{
                let splittingNames = splitContactName(item?.ContactName);
                return {
                    ...item,
                    FName: splittingNames[0]||'',
                    MName: splittingNames[1]||'',
                    LName: splittingNames[2]||'', 
                  };
            });
            
            const result = await createProject(values);
            if (result) {
                window.$(`#createproject`).modal('hide');
                setIsLoading(false);
                setTimeout(() => {
                    window.$(`#commonModal_success`).modal('show');
                }, 300);
                getProjectList();
                setTimeout(async () => {
                    // window.$(`#commonModal_success`).modal('hide');
                    navigate(`/project/${result.ID}`)
                }, 2000)
                resetForm();

            } else {
                // window.$(`#createproject`).modal('show');
                setIsLoading(false);
            }
        }
    }

    const onEdit = async () => {
        if (validateForm()) {
            setIsLoading(true); values.WMS_ProjectFacilityUserAccess = [...primaryContact, ...secondaryContacts]?.map((item)=>{
                let splittingNames = splitContactName(item?.ContactName);
                return {
                    ...item,
                    FName: splittingNames[0] || "",
                    MName: splittingNames[1] || "",
                    LName: splittingNames[2] || "", 
                  };
            });
            let res = await createProject(_.pick(values, ['ID', 'Title', 'Description', 'EndDate', 'WMS_ProjectFacilityUserAccess','WorkflowId']));
            if (res) {
                if (values?.ID) {
                    setIsLoading(false);
                    setType(MessageType.Success);
                    setMessage('Your changes have been successfully saved!');
                    window.$(`#${_modalID}`).modal('hide');
                    updateOverView();
                }
            } else {
                setIsLoading(false);
            }
        }
    }


    return (
        <>
            <MessageBox message={message} setMessage={setMessage} type={type} />
            <Modal
                showCancel={false}
                showSuccessIcon={true}
                id={'_success'}
                title={'Success'}
                content={'Project created successfully and an email has been sent to the contact person(s) containing an account activation link to complete facility profiling.'}
                submit={'Ok'}
                onClick={OK}
            />
            <div className="modal fade createproject" id={`${_modalID}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            {values && values?.ID > 0 ? <h3 className="crt-project-title" id="staticBackdropLabel">Edit Project</h3> : <h3 className="crt-project-title" id="staticBackdropLabel">Create Project</h3>}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                        </div>
                        <div className="modal-body">
                            <div className="create-project">
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextInput
                                            maxLength={100}
                                            required={true}
                                            type="text"
                                            wrapperClassName='mr-bt-35'
                                            value={values?.Title}
                                            error={errors && errors.Title}
                                            name="title"
                                            id="Project Name"
                                            placeholder="Project name"
                                            label="Project name"
                                            // onChange={(e) => handleChange("Title", formatProjectName(e.target.value) )}
                                            onChange={(e) => handleChange("Title", e.target.value)}
                                            className="inputformdata form-control"
                                        />
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className={`select-crt-pt mr-bt-35 ${errors && errors.WorkflowId && 'redborder'}`}>
                                            <select
                                                disabled={values?.ID > 0}
                                                className={`form-select crt-select`}
                                                value={values?.WorkflowId || ""}
                                                error={errors && errors?.WorkflowId}
                                                onChange={(e) => handleChange("WorkflowId", Number(e.target.value))}
                                                aria-label="Filter select" required
                                                id="business-process"
                                                wrapperClassName='mr-bt-35'
                                            >
                                                <option value="" >Select business process *</option>
                                                {_.map(workFlowsList, (i, index) => <option key={index} value={i?.value}>{i?.label}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className={`inputformContainer inputtextarea  ${errors && errors.Description && 'redborder'}`}>

                                            <textarea row="5"
                                                cols="20"
                                                value={values?.Description}
                                                onChange={(e) => handleChange("Description", e.target.value)}
                                                className=" form-control ad-textarea"
                                                id="description"
                                                placeholder=" "
                                                required
                                                maxLength={1000}
                                                name="description"></textarea>
                                            <label htmlFor="description"
                                                className="label desred">Description</label>
                                            <p className="count">{values?.Description?.length || 0}/{1000}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <TextInput
                                            maxLength={100}
                                            type="text"
                                            required={true}
                                            wrapperClassName='mr-bt-35'
                                            name="ContactName"
                                            id="contact-name"
                                            placeholder="Primary contact name"
                                            label="Primary contact name"
                                            value={(primaryContact && primaryContact.length && primaryContact[0].ContactName) || ''}
                                            error= { (errors &&
                                                errors &&
                                                errors.primaryContact &&
                                                errors.primaryContact[0] &&
                                                errors.primaryContact[0][0] &&
                                                errors.primaryContact[0][0]?.ContactName) ||
                                              null}
                                            onChange={(e) => handleAccessibleUsers("ContactName", e.target.value, 0, true)}
                                            className="inputformdata form-control"
                                        />
                                    </div>
                                    <div className="col-md-6 ">
                                        <TextInput
                                            maxLength={100}
                                            type="text"
                                            disabled={(primaryContact && primaryContact.length && primaryContact[0].ID > 0) || ''}
                                            required={true}
                                            wrapperClassName='mr-bt-35'
                                            name="contact-email"
                                            id="contact-email"
                                            placeholder="Primary contact email"
                                            label="Primary contact email"
                                            value={(primaryContact && primaryContact.length && primaryContact[0].Email) || ''}
                                            error= { (errors &&
                                                errors &&
                                                errors.primaryContact &&
                                                errors.primaryContact[0] &&
                                                errors.primaryContact[0][0] &&
                                                errors.primaryContact[0][0]?.Email) ||
                                              null}                                           
                                            onChange={(e) => handleAccessibleUsers("Email", e.target.value, 0, true)}
                                            className="inputformdata form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="inputformContainer">
                                            <DatePickerComponent
                                                required={true}
                                                name="DueDate"
                                                selected={values?.EndDate ? new Date(values?.EndDate) : null}
                                                value={values?.EndDate ? moment(values?.EndDate).format("MM/DD/YYYY") : null}
                                                wrapperClassName={`mr-bt-35  ${errors && errors.EndDate ? "redborder" : ""}`}
                                                onChange={(date) => setValues({ ...values, EndDate: date })}
                                                className={`${values?.Status === ProjectStatus.Completed && "disabled-textbox"} date-picker-styles`}
                                                placeholder='MM-DD-YY'
                                                label="Due date"
                                                iconClass={'calendar-endDate'}
                                                futureDateOnly={true}
                                                disabled={values?.Status === ProjectStatus.Completed}
                                                disableCalendar={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-header mb-3">
                                        <span className="crt-project-title" id="staticBackdropLabel">Other Contacts
                                            {
                                                secondaryContacts?.length < 2 && <label className="cursor-pointer add-contact-cr" onClick={handlecontactsAdd} >
                                                    <Icon
                                                        icon={"material-symbols:add"}
                                                        className="my-1 ms-2 cursor-pointer" width="20" height="20" />
                                                    Add contacts
                                                </label>
                                            }
                                        </span>
                                    </div>
                                    {secondaryContacts && secondaryContacts?.map((input, index) => { 
                                        return <Fragment key={index}>
                                            <div className="col-md-5" >
                                                <TextInput
                                                    maxLength={100}
                                                    type="text"
                                                    name="ContactName"
                                                    value={input.ContactName} 
                                                    error= { (errors &&
                                                        errors &&
                                                        errors.secondaryContacts &&
                                                        errors.secondaryContacts[0] &&
                                                        errors.secondaryContacts[0][index] &&
                                                        errors.secondaryContacts[0][index]?.ContactName) ||
                                                      null}                                                                                                                        
                                                    className="inputformdata form-control"
                                                    id="provider"
                                                    label="Contact name"
                                                    placeholder="Contact name"
                                                    wrapperClassName="mr-bt-35"
                                                    onChange={(e) => handleAccessibleUsers("ContactName", e.target.value, index)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextInput
                                                    disabled={input.ID > 0}
                                                    type="text"
                                                    name="Email"
                                                    value={input.Email}
                                                    error= { (errors &&
                                                        errors &&
                                                        errors.secondaryContacts &&
                                                        errors.secondaryContacts[0] &&
                                                        errors.secondaryContacts[0][index] &&
                                                        errors.secondaryContacts[0][index]?.Email) ||
                                                      null}                                             
                                                    className="inputformdata form-control"
                                                    id="provider"
                                                    label="Contact email"
                                                    placeholder="Contact email"
                                                    wrapperClassName="mr-bt-35"
                                                    onChange={(e) => handleAccessibleUsers("Email", e.target.value, index)}
                                                />
                                            </div>
                                            <div className="col-md-1 cr-del">
                                                {
                                                    !input.ID > 0 && <Icon onClick={() => handleContactsDelete(index)}
                                                    icon={"fluent:delete-28-regular"}
                                                    className="m-2 cursor-pointer" width="20" height="20" />
                                                }
                                               
                                            </div>
                                        </Fragment>
                                    })}
                                    <div className="col-md-12">
                                        {values?.ID && values?.ID > 0 ?
                                            <button type="button" disabled={isLoading} className="snd-btn" onClick={() => onEdit()}>
                                                {isLoading && <img
                                                    src={image.loader.default}
                                                    className={'load__icon1'}
                                                    alt="loader"
                                                    width={20}
                                                    height={20}
                                                />} {" "}Save</button> :
                                            <button type="button" disabled={isLoading} className="snd-btn" onClick={() => onSendInvite()}>
                                                {isLoading ? <img
                                                    src={image.loader.default}
                                                    className={'load__icon1'}
                                                    alt="loader"
                                                    width={20}
                                                    height={20}
                                                /> : <Icon icon="ion:paper-plane-outline" />} {" "}Send invite</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = state => ({
    workflows: state.projects && state.projects.workflows
});

const mapDispatchToProps = { createProject, getWorkFlows, getProjectList };

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
