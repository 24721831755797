import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { validate } from 'validate.js';
import images from '../../../assets/img'
import TextField from '../../../components/Common/TextInput';
import validationSchema from './schema';
import _ from 'lodash';
import { MessageType } from '../../../util/enum';
import MessageBox from '../../../components/Common/MessageBox';

function ForgetPassword() {
    const navigate = useNavigate()
    const [error, setError] = useState("");
    const [email, setEmail] = useState();
    const [type, setType] = useState();
    const [message, setMessage] = useState()

    const credentials = {
        email: email
    }
    const validateForm = () =>{
        const errors = validate(credentials, validationSchema);
        setError(errors)
        if(errors){
            const message = !_.isEmpty(email) ?
            error[Object.keys(error)[0]][0] : "Please enter your email"
            setType(MessageType.Error);
            setMessage(message);
            return false;
        } else {
            return true
        }
    }
    const resetPasswordhandler = async (e) => {
       if(validateForm()){ 
        navigate('/resetpassword/request')
       } 
    }
    return (
        <>
            <MessageBox message={message} setMessage={setMessage} type={type} />
            <section className="login-section">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="main-img-div">
                                <h3 className="log-txt">Streamline your process<br />with ease</h3>
                                <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="login-content">
                                <div className="logomain">
                                    <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
                                        <p className="tm">powered by SecondOpinionExpert</p></h2>
                                    <p>Facility workflow management</p>
                                    <div className="fr-psd">
                                        <h2>Get your password</h2>
                                        <p>Please enter the email associated with your account.</p>
                                    </div>
                                </div>
                                    <TextField
                                        type="email"
                                        name="email"
                                        value={email}
                                        error={error && error.email}
                                        id=""
                                        placeholder="Email"
                                        label="Email"
                                        className="input form-control"
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyPress={resetPasswordhandler}
                                        wrapperClassName='user-name'
                                    />

                        
                                <div className="col-12 mx-auto mt-20 mb-20 mr-btm">
                                    <div className="text-center">
                                        <p>Remember your password? <Link to="/login">Try logging in</Link></p>
                                    </div>
                                </div>
                                <div className="d-grid col-12 mx-auto mt-20 mb-20">
                                    <div className="btnbox">
                                        <button className="fullwidthbtn ft-size" onClick={resetPasswordhandler}>Reset password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgetPassword;