/**
 * Combine All Reducers
 */

import { combineReducers } from "redux";
import auth from "./auth/reducer";
import error from "./error/reducer";
import projects from "./projects/reducer";
// Combine all
const appReducer = combineReducers({
  auth,
  error,
  projects
});

// Setup root reducer
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
