import React,{useState} from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

const CommonModal = ({ onClick, title, content, submit, cancel, id = "", showSuccessIcon = false, showCancel = true, handleResend}) => {
  
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    window.$(`#commonModal${id}`).modal("hide");
  };
  return (
    <div
      className="modal fade"
      id={`commonModal${id}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-small modal-dialog-centered">
        <div className="modal-content suc-modal-content">
          <div className="modal-body">
            <div className="successfull-message text-center">
              <div className="success-icon ">
                {showSuccessIcon && <span className="iconify" data-icon="material-symbols:check-circle"></span>}
              </div>
              <div className="success-header">
                <h3>{title}</h3>
                <p>{content}</p>
                <button className="success-btn" data-bs-dismiss="modal"onClick={async () => {
                if (id === '_activation' && showCancel) {
                  if (!loading) {
                    setLoading(true);
                    await handleResend();
                    setLoading(false);
                  }
                } else return onClick();
              }}>{submit}</button>
                {showCancel && <button className="cancel-btn" onClick={handleCancel}> 
                  {cancel}
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommonModal);
