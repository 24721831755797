import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar"; 

const DashBoardLayout = ({ children, skipLayout, sectionClass }) => {  
  return (
    <div id="layout-wrapper">
       <Header />
      {skipLayout ? children : <> 
        <Sidebar />
        <div className="main-content">
          <div className={`page-content ${sectionClass}`}>{children}</div>
        </div>
      </>}
      
    </div>
  );
};

export default DashBoardLayout;
