import moment from "moment";
import client from "../../services/api-client";
import { FacilityUserForm, CategoryType } from "../../util/enum";
import { errorHandler } from "../error/action";
import _ from "lodash";
export const getWorkFlows = () => async (dispatch) => {
  try {
    let res = {};
    res = await client.service("wms-workflows").find();
    if (res && res.data) {
      dispatch({
        type: "WORK_FLOWS",
        data: res.data
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const createProject = (data) => async (dispatch) => {
  try {
    let res = {};
    if (data.ID > 0) {
      res = await client.service("wms-projects").patch(data.ID, data);
    } else {
      res = await client.service("wms-projects").create(data);
    }
    if (res) {
      return res;
    }
    return false
  } catch (error) {
    errorHandler(error);
    return false;
  }
};


export const getProjectList = (limit = 10, pageno = 0,queryOptions ={},sort = -1) => async (dispatch) => {
  try {
    let data = {
      $sort: {
        LastModifiedAt: sort,
      },
      ...queryOptions,
      $limit: limit,
      $skip: pageno * limit,
    };
    let res = await client.service("wms-projects").find({ query: data });
    if (res) {
      dispatch({
        type: "PROJECTS",
        data: res
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProjectById = (id,query={}) => async (dispatch) => {
  try {
    let res = await client.service("wms-projects").get(id,{query});
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const resendInvite = (id, email) => async dispatch => {
    try {
        const res = await client.service('wms-projects').patch(id, { $notify: true, $resendEmailId : email });
        if (res) {
            return res;
        }
        return false
    } catch (error) {
        errorHandler(error);
        return false;
    } 
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    let query = { $limit: "-1", IsActive: 1, DocCategoryId: { $in: [1, 2, 3, 4, 5, 6] }, }
    let res = await client.service("wms-doc-types").find({ query: query });
    if (res) {
      dispatch({
        type: "DOC_TYPES",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getDocumentCategories = () => async (dispatch) => {
  try {
    let res = await client.service("wms-doc-category").find({ query: { IsOnboardingDoc: false, Tab: FacilityUserForm.DOCS, $limit: -1 } });
    if (res) {
      dispatch({
        type: "DOC_CATEGORY",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getDocumentCategoriesForExclude = () => async (dispatch) => {
  try {
    let res = await client.service("wms-doc-category").find({ query: { CategoryType: CategoryType["Onboarding Documents"] } });
    if (res) {
      dispatch({
        type: "DOC_CATEGORY_EXCLUDE",
        data: res.data,
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getExcludedDocs = (id) => async (dispatch) => {
  try {
    const res = await client.service("wms-doctypesexcluded").find({ query: { ProjectId: id, $limit: -1 } });
    if (res) {
      dispatch({
        type: "EXCLUDED_DOCS",
        data: res,
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const excludeDocTypes = (ProjectId, docTypes) => async (dispatch) => {
  try {
    let res;
    docTypes &&
      !_.isEmpty(docTypes) &&
      (res = await Promise.all(
        _.map(docTypes, async (doc, index) => {
          if (doc.ID) {
            return await client.service('wms-doctypesexcluded').patch(doc.ID, {
              ProjectId: Number(ProjectId),
              DocTypeId: Number(doc.DocTypeId),
              $NeedPercentagePatch: (index == (docTypes.length - 1)) ? true : false
            })
          } else {
            return await client.service('wms-doctypesexcluded').create({
              ProjectId: Number(ProjectId),
              DocTypeId: Number(doc.DocTypeId),
              $NeedPercentagePatch: (index == (docTypes.length - 1)) ? true : false
            })
          }
        })))
    if (res) {
      return true;
    }
    return false
  }
  catch (error) {
    errorHandler(error);
    return false
  }
}

export const includeDocTypes = (docTypes) => async (dispatch) => {
  try {
    let res;
    docTypes &&
      !_.isEmpty(docTypes) &&
      (res = await Promise.all(
        _.map(docTypes, async (doc) => {
          if (doc.ID) {
            return await client.service('wms-doctypesexcluded').remove(doc.ID);
          }
          return doc;
        }))

      )
    if (res) {
      return true;
    }
    return false
  }
  catch (error) {
    errorHandler(error);
    return false
  }
}

export const getFacilityProfileDocumentCategories = () => async (dispatch) => {
  try {
    let res = await client.service("wms-doc-category").find({ query: { IsOnboardingDoc: true, Tab: FacilityUserForm.DOCS, $limit: -1 } });
    if (res) {
      dispatch({
        type: "FACILITY_DOC_CATEGORY",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocuments = (id) => async (dispatch) => {
  try {
    let res = await client.service("wms-documents").find({ query: { $limit: '-1', ProjectId: id } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const openDocument = (DocExtURL) => async (dispatch) => {
  try {
    let res = await client.service("wms-documents").find({ query: { DocExtURL, showDoc: true } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const startOnBoarding = (id) => async (dispatch) => {
  try {
    let res = await client.service("generate-project-task").create({ ProjectId: id, OnboardingDate: new Date() });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getTaskByProjectId = (id) => async (dispatch) => {
  try {
    let res = await client.service("wms-project-tasks").find({
      query: { $limit: -1, ProjectId: id, $isDependent: true },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getTaskById = (id, ProjectId) => async (dispatch) => {
  try {
    let res = await client.service("wms-project-tasks").find({
      query: { $limit: -1, ID: id, ProjectId: ProjectId, $isDependent: true },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllTask = (limit = 50, pageno = 0,sort = -1) => async (dispatch) => {
  let data = {
    $sortStartDate: true,
    $limit: limit,
    $skip: pageno * limit,
    $isProject:true,
    $isAspNetUsers: true
  };
  try {
    let res = await client.service("wms-project-tasks").find({query:data});
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
}

export const getAllTaskBetweenDates = (currDate=new Date(), value={}) => async (dispatch) => {
  const fromDate = moment(currDate).startOf('month').format('YYYY-MM-DD');
  const toDate = moment(currDate).endOf('month').format('YYYY-MM-DD')
  let data = {  
    $fromDate: fromDate ,
    $toDate : toDate,
    $isCalendar : true,
    ...value
  };
  try {
    let res = await client.service("wms-project-tasks").find({query:data});
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
}
export const editTask = (data) => async (dispatch) => {
  try {
     let res = await client.service("wms-project-tasks").patch(data.ID, data);
      if (res) {
          return res;
      }
      return false
  } catch (error) {
      errorHandler(error);
      return false;
  } 
};
export const getAllUsers = () => async (dispatch) => {
  try {
    let res = await client.service("AspNetUsers").find({query:{ $limit: -1,$notRestrict: true }});
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getAllStatus = () => async (dispatch) => {
  try {
    let res = await client.service("wms-task-status").find({query:{ $limit: -1}});
    if (res && res.length) {
      return res;
      
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getAllTaskDashBoard = ( query = {}, limit=-1, pageno = 0) => async (dispatch) => {
  let data = { 
    $limit: limit,
    $skip: pageno * limit,
    ...query
  };
   
  try {
    let res = await client.service("dashboard-project-task").find({query:data});
    if (res) {
      
      return res;

    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
}
export const getAllActiveOverDueTask = (limit = 10, pageno = 0, TabStatus) => async (dispatch) => {
  let data = { 
    $limit: limit,
    $skip: pageno * limit,
    $isDashboard:true, 
    TabStatus,
    TaskEndDate: moment().format("YYYY-MM-DD")
  };
  try {
    let res = await client.service("dashboard-project-task").find({query:data});
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
}