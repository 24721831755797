import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { logout } from "../../../store/auth/action";
import images from '../../../assets/img/index'
import { connect, useSelector } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
const Header = (props) => { 
    const {logout} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = location.pathname;
    const spliting = activeTab.split("/")
    const splitActive = spliting[1]
    const [show, setShow] = useState(false);
    const user = useSelector((state)=> state.auth);
    const temp = user && user.profile && user.profile.UserName;
    let userIcon = ""
    if(temp)
    {
        const fname = temp[0];
        const lname = temp[1];
        userIcon = fname.toUpperCase()+lname.toUpperCase();
    } 

    const handleLogout = async () => {
        await logout();
        navigate('/login'); 
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);
    return (<>
     <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex wt-15">

                    {/* <!-- LOGO -->  */}
                    <Link className="logo logo-main">
                        <img src={images.logoicon} alt="" height="24" /> <span className="logo-txt">CLHFLOW</span>
                    </Link>
                    <button type="button" class="btn btn-sm px-3 font-size-20 header-item waves-effect mobile-menu-icon" id="vertical-menu-btn" onClick={handleShow}>
                            <span class="iconify" data-icon="mdi:hamburger-menu-back"  data-rotate="180deg"></span>
                        </button>  
                </div>
                <div className="d-flex d-search wt-70">
                    <form className="app-search  d-lg-block">
                        <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Search by project name, task name..." />
                            <span> <Icon icon="ic:baseline-search" color="rgba(255, 255, 255, 0.375)" /></span>
                        </div>
                    </form>
                </div>
                <div className="d-flex wt-15 js-cn-end">
                    <div className="navbar-brand-box-data">
                    </div>

                    <div className="dropdown  d-lg-inline-block ms-1">
                        <button type="button" className="btn header-item noti-icon waves-effect"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <Icon icon="bi:bell-fill" width="22" /> */}
                        </button>
                    </div>
                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="user-icon">{userIcon}</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            {/* <Link className="dropdown-item" >
                                <Icon icon={"mdi:user"} className="iconify" />
                                <span key="t-profile">Profile</span>
                            </Link>
                            <div className="dropdown-divider"></div> */}
                            <Link className="dropdown-item text-danger" onClick={handleLogout}>
                                <Icon icon={"ri:logout-circle-r-line"} className="iconify" />
                                <span key="t-logout"> Logout</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <Offcanvas show={show} onHide={handleClose} backdrop={false} className="custom-offcanvas">
        <Offcanvas.Body className="m-0 p-0">
                    <div className="offCanvas-menu">
                <div data-simplebar className="h-100">
                    <div id="sidebar-menu">
                        <div className="div-create mm-active">
                            <Link className="crt-prj" data-bs-toggle="modal" data-bs-target="#createproject" >
                                <Icon icon={"ic:baseline-plus"} className="iconify-inline" style={{ verticalAlign: "middle", fontSize: "26px" }} />
                                <span onClick={handleClose}> Create</span></Link>
                        </div>
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className={`${activeTab.includes('/dashboard') ? "mm-active" : ""}`}>
                                <Link to={''} onClick={(e) => { e.preventDefault(); navigate('/dashboard'); handleClose()}} className={`${activeTab === "/dashboard" ? "waves-effect active" : "waves-effect"}`}>
                                    <Icon icon={"material-symbols:home-outline"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span>Home</span></Link>
                            </li>
                            <li className={`${activeTab.includes('/project') || activeTab.includes('/docexclude') ? "mm-active" : "" || splitActive === 'project' ? "mm-active" : ''}`}>
                                <Link to={''} onClick={(e) => { e.preventDefault(); navigate('/project'); handleClose()}} className={`${activeTab === "/project" ? "waves-effect active" : "waves-effect" || splitActive === 'project' ? "waves-effect active" : 'waves-effect'}`}>
                                    <Icon icon={"material-symbols:share-outline"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span>Projects</span>
                                </Link>
                            </li>
                            <li className={`${activeTab.includes('/task') ? "mm-active" : ""}`}>
                                <Link  to={''} onClick={(e) => { e.preventDefault(); navigate('/task');handleClose()}} className={`${activeTab === "/task" ? "waves-effect active" : "waves-effect" || splitActive === 'task' ? "waves-effect active" : 'waves-effect'}`}>
                                    <Icon icon={"carbon:document-tasks"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Tasks</span>
                                </Link>
                            </li>
                            <li className={`${activeTab.includes('/calendar') ? "mm-active" : ""}`}>
                                <Link className={`${activeTab === "/calendar" ? "waves-effect active" : "waves-effect" || splitActive === 'calendar' ? "waves-effect active" : 'waves-effect'}`} onClick={(e) => { e.preventDefault(); navigate('/calendar');handleClose()}}>
                                    <Icon icon={"material-symbols:calendar-today-outline-rounded"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Calendar</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"ph:envelope-simple-light"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Inbox</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"mdi:report-timeline-variant"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Reporting</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"carbon:settings"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Settings</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
    
    </>
       
    )
}

const mapDispatchToProps = { logout, };
const mapStateToProps = (state) => ({
    profile: state.auth && state.auth.profile,
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);