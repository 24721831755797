import React from "react";
import { Link } from "react-router-dom"; 
import { connect } from 'react-redux';

const PageNotFound = (props) => {
    const token = localStorage.getItem("feathers-jwt");
    return  <div className="content-side">
        <div className="row">
            <div className="col-lg-12">
                <div className="driverconfirmation_main">
                    <div className="driverconfirmation">
                        <img src={""} className="confirmimg" alt="" />
                        <h1>Not Found</h1>
                        <p>Ooops! The page you are looking for could not be found :(</p>
                        <Link to={token?"/dashboard":"/login"} className="cntbtn1">
                            <img src={""} alt="" />Back Home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapDispatchToProps = {
}
const mapStateToProps =(state) =>({})

export default connect(mapStateToProps,mapDispatchToProps)(PageNotFound);