// eslint-disable-next-line import/no-anonymous-default-export
export default {
    loader: require("./loader.svg"),
    logoImage:require('./log-img.webp'),
    headerLogo:require('./logo-icon.png'),
    border: require("./border.png"),
    frame: require("./frame.png"),
    logoicon: require("./logo-icon.png"),
    doodle2: require("./doodle2.png"),
    formBg: require('./frm-bg.png'),
    guestImg: require('./gl-dl.png'),
    gifdownload: require('./download.gif'),
}