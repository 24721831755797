/* eslint-disable import/no-anonymous-default-export */
export default (state = {
  
}, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          profile: { ...action.data },
        };
      case "LOGOUT":
        return {};
  
      default:
        return state;
    }
  };
  
