import { isEmpty, validate } from 'validate.js';
import hasValue from "lodash"
const message ="^Please fill in the required fields"

validate.validators.array = (arrayItems, itemConstraints) => {
    const arrayItemErrors =
      arrayItems &&
      arrayItems.reduce((errors, item, index) => {
        if (index === 0 && arrayItems.length === 1 && item && !hasValue(item)) {
          return {};
        }
        let error = validate(item, itemConstraints);

        if (error) errors[index] = error;
        return errors;
      }, {});

    return isEmpty(arrayItemErrors) ? null : arrayItemErrors;
  };

validate.extend(validate.validators, {
    emailID: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)) {
            return "^Please enter a valid email address ";
        } else {
            return null
        }
    },
    name: (value) => {
        if (value && !/[A-Za-z]/.test(value)) {
            return "enter a valid value";
        }
        else {
            return null;
        }
    },
})

export const ValidationSchema = {
    Title: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
    },
    Description: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
    }, 
    WorkflowId: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            message: "^Please fill in the required fields"
        },
    },
    EndDate: {
        presence: { allowEmpty: false,  message: "^Please fill in the required fields" },
    },

}


export const PValidationSchema = {
    primaryContact: {
        array: {
            ContactName:{
                   presence: { 
                      allowEmpty: false,
                      message: message,
                   },
                   name: true
                },
            Email: {
                presence: { 
                   allowEmpty: false,
                   message: message,
                },
                emailID: true
             },
        },
    },
}

export const SValidationSchema = {
    secondaryContacts: {
        array: {
            ContactName:{
                   presence: { 
                      allowEmpty: false,
                      message: message,
                   },
                   name: true
                },
            Email: {
                presence: { 
                   allowEmpty: false,
                   message: message,
                },
                emailID: true
             },
        },
    },
}