import React from 'react' 
import image from "../../assets/img"

const PublicLayout = ({ children , isGuest}) => {
    return (isGuest ?
        <section className="guestlogin">
            <img src={image.guestImg} alt='img' className="doodle-dl" />
            <img src={image.doodle2} alt='img' className="doodle-dr" />
            {children}
        </section> :
        <section className="login-pg">
            <section className="login-section">
                {children}
            </section>
            <footer>
                <div className="col-12 text-center ftr-top">
                    <span>© {new Date().getFullYear()} SecondOpinionExpert, Inc. All rights reserved.</span>
                </div>
            </footer>
        </section>)
}

export default PublicLayout;
