import React, { useEffect, useState } from 'react';
import TextInput from "../../../components/Common/TextInput";
import images from '../../../assets/img';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { login } from '../../../store/auth/action';
import { validate } from 'validate.js';
import _ from "lodash"
import validationSchema from './schema';
import { connect } from "react-redux"; 
import MessageBox from '../../../components/Common/MessageBox';
import { MessageType } from '../../../util/enum';
import { Icon } from "@iconify/react";

const Login = (props) => { 
    const { login } = props;
    const navigate = useNavigate('')
    const [values, setValues] = useState({ email: "", password: "", rememberMe: false });
    const [error, setError] = useState({ email: "", password: "" });
    const [type, setType] = useState();
    const [message, setMessage] = useState()
    const [loader, setLoader] = useState(false);
    const { state } = useLocation();
    const [passwordIsMasked, setPasswordIsMasked] = useState(false);

    useEffect(() => {
        const obj = localStorage.getItem("rememberMe") && localStorage.getItem("rememberMe") !== "undefined" ? JSON.parse(localStorage.getItem("rememberMe")) : null;
        if (obj !== null) {
            setValues((prev) => ({
                ...prev,
                email: obj.Email,
                password: obj.PasswordHash,
                rememberMe: obj.isRemember,
            }));
        }

    }, []);

    useEffect(() => {
        if (state && state.email) {
            setValues((prev) => ({
                ...prev,
                email: state.email,
                password: ""
            }));
        }
    }, [state]);

    const endAdornment = (passwordIsMasked, setPasswordIsMasked) => {
        return passwordIsMasked ? (
            <span>
                <Icon icon={"mdi:eye-outline"} className="iconify" id='togglePassword' onClick={() => setPasswordIsMasked(prev => !prev)}/>
            </span>
        ) : (
            <span>
                <Icon icon={"mdi:eye-off-outline"} className="iconify" id='togglePassword' onClick={() => setPasswordIsMasked(prev => !prev)}/>
            </span>
        );
      };

    const handleChange = (field, value) => {
        setValues({ ...values, [field]: value.trim() });
    };
    const handleRememberme = (value) => {
        setValues({ ...values, rememberMe: !values.rememberMe });
    };
    const validateCredentials = {
        email: values?.email,
        password: values?.password,
    };
    const credentials = {
        Email: values?.email,
        PasswordHash: values?.password,
    };
    const isRemember = values.rememberMe;
    

    const validateForm = () => {
        const errors = validate(validateCredentials, validationSchema);
        setError(errors);
        if (errors) {
          const message =
            !_.isEmpty(values.email) && !_.isEmpty(values.password)
              ? errors[Object.keys(errors)[0]][0]
              : 'Please fill in the required fields';
          setType(MessageType.Error);
          setMessage(message);
          return false;
        }
        return true;
      };

    const loginHandler = async (e) => {
        if (validateForm()) {
            setLoader(true)
            const status = await login(credentials, isRemember)
            if (!status) {
                setTimeout(() => {
                    setLoader(false);
                }, 500);
              }
            if (status.res) {
                setTimeout(() => {
                    navigate("/dashboard");
                    setLoader(false);
                }, 1000);
            } 
          } 
    }

    return (
       <>
       <MessageBox type={type} message={message} setMessage={setMessage} />
       <div className="container container-lg container-md">
            <div className="row">
                <div className="col-lg-7 col-md-6">
                    <div className="main-img-div">
                        <h3 className="log-txt">Streamline your process<br />with ease</h3>
                        <img src={images.logoImage} className="main-img img-fluid" alt='logo' />
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="login-content">
                        <div className="logomain">
                            <h2 className="log-fnt">CLH<span className="log-360">FLOW</span>
                                <p className="tm">powered by SecondOpinionExpert</p></h2>
                            <p>Facility workflow management</p>
                        </div> 
                            <TextInput
                                maxLength={100}
                                wrapperClassName='user-name'
                                type="text"
                                value={values?.email}
                                error={error && error.email}
                                name="email"
                                id=""
                                placeholder="Email"
                                label="Email"
                                className="input form-control"
                                onChange={(e) => handleChange("email", e.target.value)}
                                onKeyPress={loginHandler}
                            /> 
                            <div className='password-container'>
                            <TextInput
                                maxLength={100}
                                value={values?.password}
                                error={error && error.password}
                                id="id_password"
                                placeholder="Password"
                                label="Password"
                                name="password"
                                className="input form-control"
                                onChange={(e) => handleChange("password", e.target.value)}
                                onKeyPress={loginHandler}
                                wrapperClassName="mb-0"
                                type={passwordIsMasked ? 'text' : 'password'}
                                endAdornment={() => endAdornment(passwordIsMasked, setPasswordIsMasked)}
                            />
                            </div>
                            <div> 
                        </div>
                        <div className="col-12 mx-auto mt-20 mb-20 mr-btm">
                            <div className="fr-psd-div">
                                <label className="sp-line cursor-pointer">
                                    <input type="checkbox" name="remember" className="chkbox cursor-pointer" checked={values.rememberMe} onChange={(e) => handleRememberme(e.target.value)} /> Remember me
                                </label>
                                {/* <span className="forgot-psd mb-20 sp-line cursor-pointer"><Link to='/forgotpassword'>Forgot password?</Link></span> */}
                            </div>
                        </div>
                        <div className="d-grid col-12 mx-auto mt-20 mb-20">
                            <div className="btnbox">
                                    <button className="fullwidthbtn ft-size" type="button" onClick={loginHandler}>
                                        {loader && ( <div className="loader" ></div>)}
                                        Login
                                    </button>
                            </div>
                        </div>
                        <div className="d-grid text-center ">
                            <span className="sp-line">Issues logging in? <Link>Visit our Help Desk</Link></span>
                            <span className="sp-line-2">By using the CLHFLOW™ Platform you agree to <Link>our Terms of Service</Link> and <Link>Privacy Policy </Link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}


const mapDispatchToProps = { login };
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Login);