/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddProject from "../../../components/AddProject";


const Sidebar = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const activeTab = location.pathname;
    const spliting = activeTab.split("/")
    const splitActive = spliting[1]


    return (
        <>
            <AddProject />
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    <div id="sidebar-menu">
                        <div className="div-create mm-active">
                            <Link className="crt-prj" data-bs-toggle="modal" data-bs-target="#createproject" >
                                <Icon icon={"ic:baseline-plus"} className="iconify-inline" style={{ verticalAlign: "middle", fontSize: "26px" }} />
                                <span> Create</span></Link>
                        </div>
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className={`${activeTab.includes('/dashboard') ? "mm-active" : ""}`}>
                                <Link to={''} onClick={(e) => { e.preventDefault(); navigate('/dashboard'); }} className={`${activeTab === "/dashboard" ? "waves-effect active" : "waves-effect"}`}>
                                    <Icon icon={"material-symbols:home-outline"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span>Home</span></Link>
                            </li>
                            <li className={`${activeTab.includes('/project') || activeTab.includes('/docexclude') ? "mm-active" : "" || splitActive === 'project' ? "mm-active" : ''}`}>
                                <Link to={''} onClick={(e) => { e.preventDefault(); navigate('/project'); }} className={`${activeTab === "/project" ? "waves-effect active" : "waves-effect" || splitActive === 'project' ? "waves-effect active" : 'waves-effect'}`}>
                                    <Icon icon={"material-symbols:share-outline"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span>Projects</span>
                                </Link>
                            </li>
                            {/* <li className="disabled">
                            <Link>
                                <Icon icon={"material-symbols:share-outline"} className="iconify" style={{ fontSize: "24px" }} />
                                <span>Projects</span>
                            </Link>
                        </li> */}
                            <li className={`${activeTab.includes('/task') ? "mm-active" : ""}`}>
                                <Link  to={''} onClick={(e) => { e.preventDefault(); navigate('/task'); }} className={`${activeTab === "/task" ? "waves-effect active" : "waves-effect" || splitActive === 'task' ? "waves-effect active" : 'waves-effect'}`}>
                                    <Icon icon={"carbon:document-tasks"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Tasks</span>
                                </Link>
                            </li>

                            <li className={`${activeTab.includes('/calendar') ? "mm-active" : ""}`}>
                                <Link className={`${activeTab === "/calendar" ? "waves-effect active" : "waves-effect" || splitActive === 'calendar' ? "waves-effect active" : 'waves-effect'}`} onClick={(e) => { e.preventDefault(); navigate('/calendar'); }}>
                                    <Icon icon={"material-symbols:calendar-today-outline-rounded"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Calendar</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"ph:envelope-simple-light"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Inbox</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"mdi:report-timeline-variant"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Reporting</span>
                                </Link>
                            </li>
                            <li className="disabled">
                                <Link className="waves-effect">
                                    <Icon icon={"carbon:settings"} className="iconify" style={{ fontSize: "24px", marginRight: "4px" }} />
                                    <span >Settings</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

// const mapStateToProps = state => ({
//     profile: state.auth && state.auth.profile,
// });
// const mapDispatchToProps = {   };
// export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export default Sidebar



