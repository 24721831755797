import { truncate } from "../../util/helper";

export default {
  Title: {
    presence: { allowEmpty: false, message: "^Please fill in the required fields" },
  },
  Description: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  },
  Assignee: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  },
  Status: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  },
  Priority: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  },
  StartDate: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  }, EndDate: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  },
  WMS_ProjectDependentTasks: {
    presence: { allowEmpty: true, message: "^Please fill in the required fields" },
  }, ProcessTaskNum: {
    presence: { allowEmpty: truncate, message: "^Please fill in the required fields" },
  }, ExpectedEndDate: {
    presence: { allowEmpty: false, message: "^Please fill in the required fields" },
  },
  ExpectedStartDate: {
    presence: { allowEmpty: false, message: "^Please fill in the required fields" },
  },

};
