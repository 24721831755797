/* eslint-disable import/no-anonymous-default-export */

export default (state = {}, action) => {
    switch (action.type) {
        case 'WORK_FLOWS':
            return {
                ...state,
                workflows: action.data,
            };
        case 'PROJECTS':
            return {
                ...state,
                projects: action.data,
            };
        case 'DOC_CATEGORY':
            return {
                ...state,
                doc_category: action.data,
            };
        case 'DOC_CATEGORY_EXCLUDE':
            return {
                ...state,
                doc_category_exclude: action.data,
            };
        case 'EXCLUDED_DOCS':
            return {
                ...state,
                excluded_docs: action.data,
            }
        case 'FACILITY_DOC_CATEGORY':
            return {
                ...state,
                facility_doc_category: action.data,
            };
        case 'DOC_TYPES':
            return {
                ...state,
                doc_types: action.data,
            };
        default:
            return state;
    }
}