
import _ from "lodash";
import moment from "moment/moment";
import { ProjectHealth, ProjectStatus, TaskStatus } from '../util/enum.js';

export const commaSeperatedString = (diagnosticstests) => {
  //Array of objects
  return diagnosticstests.map((d) => {
    return d?.name;
  });
};

export const filterDuplicates = (array, areEqual) => {
  return array.filter((item, pos) => {
    return array.findIndex((other) => areEqual(item, other)) === pos;
  });
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{4})(\d{2})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

export const calculateAge = (date) => {
  const today = new Date().getTime();
  const birthday = new Date(date).getTime();
  const age_mili = Math.abs(today - birthday);

  let days = Math.floor(age_mili / (1000 * 3600 * 24));
  let years = Math.floor(days / 365);
  days -= years * 365;
  let months = Math.floor(days / 31);
  days -= months * 31;
  return `${years} yrs`;
};

export function getGender(key) {
  switch (key) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Others";
    default:
      return "";
  }
}

export function getFullAddress(address, state) {
  const stateName =
    (_.isArray(state) &&
      _.find(
        state,
        (e) => e.id === (Number(address?.state))
      )) ||
    {};
  const fulladdress = [
    address?.line1,
    address?.line2,
    address?.city,
    stateName.name,
    address?.zip,
  ];
  return fulladdress.filter(Boolean).join(", ");
}

export const validateEmail = (email) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  return regex.test(email)
};

export const sortAlbhabetical = (a, b) => {
  // converting to uppercase to have case-insensitive comparison
  const name1 = a.label.toUpperCase();
  const name2 = b.label.toUpperCase();

  let comparison = 0;

  if (name1 > name2) {
    comparison = 1;
  } else if (name1 < name2) {
    comparison = -1;
  }
  return comparison;
}

export const getFullNameText = ({ fname, lname, mname }) => {
  return `${fname ? fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase() : ""} ${mname ? mname.charAt(0).toUpperCase() + mname.slice(1).toLowerCase() : ""} ${lname ? lname.charAt(0).toUpperCase() + lname.slice(1).toLowerCase() : ""}`
};

export const getDateasObject = (date) => {
  return { day: new Date(date).getDate(), month: new Date(date).getMonth() + 1, year: new Date(date).getFullYear() }
}

export const objectToDate = (date) => {
  return `${date.day.toString().padStart(2, "0")}/${date.month.toString().padStart(2, "0")}/${date.year.toString()}`
}

export const truncate = (input) => input && input.length > 24 ? `${input.substring(0, 24)}...` : input;

export const loadYears = () => {
  let yearList = [];
  let currentYear = new Date().getFullYear();
  let earliestYear = 1970;
  while (currentYear >= earliestYear) {
    let yearoption = {};
    yearoption.label = currentYear;
    yearoption.value = currentYear;
    yearList.push(yearoption);
    currentYear -= 1;
  }
  return yearList;
}

export const capitalizeFirstLetter = (title, isCaseType = false) => {
  if (title) {
    return title.split(" ").map(string => {
      return string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase() + " ";
    })
  }
  return "";
}

export const adjustForTimezone = (date, adjustTimeForTask) => {
  if (date && typeof date === "boolean") {
    return null;
  }
  if (date && typeof date === "object") {
    if (adjustTimeForTask) {
      date.setHours("23");
      date.setMinutes("59");
      date.setSeconds("59");
    }
    else {
      date.setHours("17");
      date.setMinutes("30");
      date.setSeconds("00");
    }


  }
  return date
}
export const splitContactName = (name) => {
  let capitalizeName = name?.split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizeName;
}
export const captailizeFirstLetters = (string = '') => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  return string
}

export const fullName = ({ FName, MName, LName }) =>
`${captailizeFirstLetters(FName)} ${MName ? captailizeFirstLetters(MName) : ''} ${LName ? captailizeFirstLetters(LName): ''}`


export const capitalizeName = (name = '') => {
  if (name && typeof name === 'string') {
    const tempName = name.trim().split(' ')
    let res = ''
    for (let i = 0; i < tempName.length; i++) {
      if (!tempName.length) return res
      if (tempName[i]) res = res + ' ' + captailizeFirstLetters(tempName[i])
    }
    return res
  }
}
export const getProjectOverview = (data) => {
  if (data) {
    return {
      Title: data.Title,
      WorkflowId: data.WorkflowId,
      workflowTitle: data.WMS_Workflows && _.startCase(_.toLower(data.WMS_Workflows.Title)),
      createdOn: `${moment(data.CreatedAt).format('dddd')},  ${moment(data.CreatedAt).format("MMMM Do YYYY")} `,
      Description: data.Description,
      ID: data.ID,
      ContactEmail: data.ContactEmail,
      ContactName: data.ContactName,
      EndDate: data.EndDate,
      Status: data.Status,
      updatedOn: `${moment(data.LastModifiedAt).format('dddd')},  ${moment(data.LastModifiedAt).format("MMMM Do YYYY")} `,
      PrimaryContact: data.WMS_ProjectFacilityUserAccess && data.WMS_ProjectFacilityUserAccess.filter(c => c.Title === 1)?.map((item) => {
        return {
          ...item,
          ContactName: fullName(item)
        }
      }),
      OtherContacts: data.WMS_ProjectFacilityUserAccess && data.WMS_ProjectFacilityUserAccess.filter(c => c.Title !== 1)?.map((item) => {
        return {
          ...item,
          ContactName: fullName(item)
        }
      }),
      OnboardingDate:data.OnboardingDate
    }
  }
}

export const doctypeByCategory = (doccategory, doctype) => {
  let types = {};
  doccategory && doccategory.map(cat => {
    return _(doctype?.filter(dt => dt.DocCategoryId === cat.ID))
      .groupBy('Title')
      .forOwn(function (v, k) {
        if (!types.hasOwnProperty(cat.Title)) {
          types[cat.Title] = [_.orderBy(v, [c => c.Description && c.Description.toLowerCase()], ['asc'])];
        } else {
          types[cat.Title].push(_.orderBy(v, [c => c.Description && c.Description.toLowerCase()], ['asc']))
        }
        return types
      });
  })
  return types
}

export const getDateFromDateString = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
export const getDaysBetweenDates = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffTime = date2.getTime() - date1.getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  return diffDays ? diffDays : 0;
}

export const getProjectStatus = (status) => {
  switch (status) {
    case ProjectStatus.InProgress:
      return {
        name: "In Progress",
        color: 'ble-btn prj-btn'
      };
    case ProjectStatus.Completed:
      return {
        name: "Completed",
        color: 'grn-btn prj-btn'
      };
    case ProjectStatus.Abandoned:
      return {
        name: "Abandoned",
        color: 'yel-btn prj-btn'
      };
    case ProjectStatus.Open:
      return {
        name: "Open",
        color: 'yel-btn prj-btn'
      };
    case ProjectStatus.OnHold:
      return {
        name: "On Hold",
        color: 'yel-btn prj-btn'
      };
    default: {
      return {
        name: "", color: 'prj-btn'
      }
    }

  }
}
export const getProjectHealth = (health) => {
  switch (health) {
    case ProjectHealth.Ontrack:
      return {
        name: "On-track",
        color: 'grn-btn'
      };
    case ProjectHealth.AtRisk:
      return {
        name: "At Risk",
        color: 'red-btn'
      };
    case ProjectHealth.RequiresAttention:
      return {
        name: "Requires Attention",
        color: 'yel-btn'
      };
    default: {
      return {
        name: "On-track",
        color: 'grn-btn'
      }
    }

  }
}

export const getProjectHealthColor = (health) => {
  switch (health) {
    case ProjectHealth.Ontrack:
      return {
        name: "On-track",
        color: 'ontrack'
      };
    case ProjectHealth.AtRisk:
      return {
        name: "At Risk",
        color: 'at-risk'
      };
    case ProjectHealth.RequiresAttention:
      return {
        name: "Requires Attention",
        color: 'requires-attention'
      };
    default: {
      return {
        name: "On-track",
        color: 'ontrack'
      }
    }

  }
}

// export const getProjectProgressColor = (color) => {
//   switch (color) {
//     case ProjectStatus.InProgress :
//         return {
//           name: "In Progress",
//           color: 'bg-violet',
//           textColor:"violet",
//         };
//     case ProjectStatus.Completed:
//         return {
//           name: "Completed",
//           color: 'bg-green',
//           textColor:"green",
//         };
//     case ProjectStatus.Abandoned:
//         return {
//           name: "Abandoned",
//           color: 'bg-yellow',
//           textColor:"yellow",
//         };
//     case ProjectStatus.Open:
//         return {
//           name: "Open",
//           color: 'bg-yellow',
//           textColor:"yellow",
//         };
//     case ProjectStatus.OnHold:
//         return {
//           name: "On Hold",
//           color: 'bg-yellow',
//           textColor:"yellow",
//         };
//     default:{
//         return {
//           name: "", color: ''
//         }
//       }

//   }
// }
export const getProjectProgressColor = (color) => {
  switch (color) {
    case ProjectHealth.AtRisk:
      return {
        name: "At-risk",
        color: 'bg-red',
        textColor: "red",
      };
    case ProjectHealth.Ontrack:
      return {
        name: "On-track",
        color: 'bg-green',
        textColor: "green",
      };
    case ProjectHealth.RequiresAttention:
      return {
        name: "Requires-attention",
        color: 'bg-yellow',
        textColor: "yellow",
      };
    default: {
      return {
        name: "", color: ''
      }
    }

  }
}

export const getTotalProgressPercentage = (percentage) => {
  let totalPercentage = 0;
  percentage?.forEach((item) => {
    totalPercentage += item.Percentage;
  });
  const progress = (totalPercentage * 100) / 600;
  return progress && progress.toFixed(0) || 0;
}

export const formatProjectName = (name) => {
  let capitalizeName = name.split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  return capitalizeName;
}

export const formatExcludedDocs = (excludedDocs) => {
  let docsList = [];
  if (excludedDocs) {
    docsList = _.map(excludedDocs, c => {
      return {
        ID: c?.ID,
        DocTypeId: c?.DocTypeId,
      }
    })
  }
  return docsList;
}

export function getPriority(status) {
  switch (status) {
    case 1:
      return 'Low';
    case 2:
      return 'Medium';
    case 3:
      return 'High';
    default:
      return '';
  }
}
export function getInitials(name) {
  var initials = name?.split(' ')
      .map(function (word) {
          return word.charAt(0).toUpperCase();
      })
      .join('');

  if (initials?.length >= 2) {
      return initials?.substring(0, 2);
  }

  return initials;
}

export const getDay = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString();
  const dateParts = formattedDate.split("/");
  const day = parseInt(dateParts[1]);
  return day;
}

