import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// Actions
import { removeError } from "../../../store/error/action";

const ErrorBar = ({ removeError, error }) => {
  const [message, setMessage] = React.useState();

  useEffect(() => {
    if (error && error.message) {
      if (error.message !== "Your session is expired. Please re-login") {
        setMessage(error.message);
      }
      setTimeout(() => {
        setMessage();
        removeError();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {message && (
        <div className="error_box">
          <Icon
            className="iconify"
            icon={"akar-icons:circle-alert"}
            onClick={removeError}
          />
          {/* <span
            className="iconify"
            data-icon="akar-icons:circle-alert"
            onClick={removeError}
          ></span> */}
          {message}
          <button
            onClick={() => {
              setMessage();
            }}
          >
            <Icon icon={"codicon:chrome-close"} className="iconify clsbtn" />
            {/* <span className="iconify clsbtn" data-icon="codicon:chrome-close" /> */}
          </button>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = {
  removeError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBar);
